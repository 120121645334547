
var isTouchDevice =
    'ontouchstart' in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
; 

touchClass = isTouchDevice ? 'touch' : 'no-touch';

$('html').addClass(touchClass);

 if (!isTouchDevice) {
    $(".has-children").hover(function(){
      $(this).addClass('selected');
      }, function(){
      $(this).removeClass('selected');
    });
 } else {
    $(".has-children").on('click', function(){
        $(this).addClass('open');
    });
 }

if (isTouchDevice || $(window).width() <= 767) {
  $('.children-toggle').on('click', function(e) {
      var
          self = $(this),
          parent = self.parent()
      ;

    if (parent.hasClass('open')) {   
        location.href = self.attr('href');
    } else {
      e.preventDefault();
      $('.has-children').removeClass('open');
      parent.addClass('open');
    }
  
  });
}


// form validation

var recaptchaResponse = jQuery('#recaptchaResponse');

if( recaptchaResponse.length > 0) {
    grecaptcha.ready(function() {
        grecaptcha.execute(recaptcha_site_key, {action: 'contactForm'}).then(function(token) {
            recaptchaResponse.val(token);
        });
    });
}

function trackingScript() {

         var google_conversion_id = "947958232";
          var google_conversion_language = "en";
          var google_conversion_format = "3";
          var google_conversion_color = "ffffff";
          var google_conversion_label = "i86uCICuhGIQ2OOCxAM";
          var google_remarketing_only = false;

          $.getScript('//www.googleadservices.com/pagead/conversion.js');

          var image = new Image(1, 1); 
          image.src = "//www.googleadservices.com/pagead/conversion/947958232/?label=i86uCICuhGIQ2OOCxAM&guid=ON&script=0";  
}

$('.validate').validate({
    rules: {
        name: "required",
        email: {
            required: true,
            email: true
        },
        telephone: "required",
        detail: "required"
    },

    errorClass: 'error',
    validClass: 'valid',
    focusInvalid: false,

    errorPlacement: function (error, element) {
        console.log(error);
    },
    submitHandler: function (form) {
        var 
            form = $(form),
            validator = this,
            formData = $(form).serializeArray()
            form.find('input[type="submit"]').attr('disabled', true);
        ;

        $.ajax({
            type: 'POST',
            url: site_url + '/includes/form-submit.php',
            data: formData,
            success: function (response) {
                var 
                    response = JSON.parse(response)
                ;

                if (response[0].success) {
                    
                    if (isProduction) {
                        trackingScript();
                    }
                    
                    form.hide();
                    $('.thank-you').show();
                }
            }
        });
        
        return false;
    }
});
